import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 5px;
  flex: 1;
`

const Avatar = styled.img`
  width: 250px;
  object-fit: cover;
  height: 300px;

  @media (max-width: 1080px) {
    width: 200px;
    height: 250px;
  }

  @media (max-width: 700px) {
    height: 300px;
    width: 250px;
  }
`
const InfoContainer = styled.div`
  max-width: 250px;

  @media (max-width: 1200px) {
    max-width: 200px;
  }

  @media (max-width: 700px) {
    max-width: 250px;
  }
`
const Name = styled.h2`
  font-family: "Articulat";
  font-weight: 500;
  font-size: 20px;
  margin: 0;

  @media (max-width: 1080px) {
    font-size: 16px;
  }

  @media (max-width: 500px) {
    font-size: 20px;
  }
`
const Role = styled.p`
  font-family: "Articulat";
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  margin: 0;

  @media (max-width: 1080px) {
    font-size: 14px;
  }

  @media (max-width: 500px) {
    font-size: 14px;
  }
`

const TeamMemberItem = props => (
  <Container>
    <Avatar src={props.avatar} />
    <InfoContainer>
      <Name>{props.name}</Name>
      <Role>{props.role}</Role>
    </InfoContainer>
  </Container>
)

export default TeamMemberItem

import React from "react"
import styled from "styled-components"

const Container = styled.section`
  height: 450px;
  position: relative;
`
const InnerContainer = styled.div`
  /* background-position: center;
  background-attachment: fixed;
  -webkit-background-size: cover; */
  background-repeat: no-repeat;
  height: 100%;
  background-size: cover;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  right: 0;
  background-position: center;
  background-image: url("https://images.pexels.com/photos/1068205/pexels-photo-1068205.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
`

const FeaturedImage = props => (
  <Container>
    <InnerContainer />
  </Container>
)

export default FeaturedImage

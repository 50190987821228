import React from "react"
import styled from "styled-components"
import { SectionTitle } from "./Section-Title"
import TeamMemberItem from "./team-member-item"
import Fade from "react-reveal/Fade"

const Container = styled.div`
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: white;
`
const Copy = styled.p`
  margin: 0;
  max-width: 450px;
  font-family: "Articulat";
  font-weight: 100;
  margin: 0 0 40px 0;
`
const TeamContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 1080px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }

  @media (max-width: 700px) {
    flex-direction: column;
  }
`
const MeetTheTeam = props => (
  <Container>
    <SectionTitle>Meet the team</SectionTitle>
    <Copy>
      Our practice is committed to providing you and your family with safe,
      gentle, high-quality dental care.
    </Copy>
    <TeamContainer>
      <Fade bottom distance="20px">
        {props.teamData.map((member, index) => (
          <TeamMemberItem
            key={index}
            name={member.name}
            role={member.role}
            avatar={member.image.source_url}
          />
        ))}
      </Fade>
    </TeamContainer>
  </Container>
)

export default MeetTheTeam

import React from "react"
import styled from "styled-components"
import { SectionTitle } from "./Section-Title"
import Fade from "react-reveal/Fade"
import MainButton from "./main-button"
import { Link } from "gatsby"
import { Colors } from "../colors/Colors"
import InvisalignAwards from "./invisalign-awards"
import Spacer from "./spacer"

const Container = styled.div`
  padding: 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: white;
`
const ServicesContainer = styled.div`
  max-width: 700px;
  margin: 50px auto 0;
  padding: 0 12px;
`
const Copy = styled.p`
  font-family: "Articulat";
  /* text-transform: uppercase; */
  font-size: 15px;
  color: #333333;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 10px;
  @media (max-width: 500px) {
    text-align: center;
  }
`
const Certified = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`
const InvisalignImage = styled.img`
  height: 70px;
  width: auto;
  margin: 0;
`
const TextButton = styled(Link)`
  color: ${Colors.gold};
  font-family: "Articulat";
  font-weight: 500;
  margin-top: 16px;
`

const Invisalign = () => {
  return (
    <Container>
      <ServicesContainer>
        <Fade cascade bottom distance="20px"></Fade>
        <InvisalignAwards />
      </ServicesContainer>
      <Spacer size={24} />
      <TextButton style={{ fontWeight: 600 }} to="/dentistry/invisalign">
        Learn about Invisalign
      </TextButton>
    </Container>
  )
}

export default Invisalign

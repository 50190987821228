import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import IntroHeader from "../components/intro-header"
import IntroSection from "../components/intro-section"
import FeaturedImage from "../components/featured-image"
import OurServices from "../components/our-services"
import MeetTheTeam from "../components/meet-the-team"
import NewPatient from "../components/new-patient"
import ServiceTabs from "../components/service-tabs"
import Invisalign from "../components/Invisalign"
import { graphql } from "gatsby"

const ServiceContainer = styled.div`
  display: block;
  @media (max-width: 800px) {
    display: none;
  }
`

class IndexPage extends React.Component {
  state = {}
  render() {
    const data = this.props.data.home.edges[0].node.acf
    const services = this.props.data.services.edges
    const teamData = this.props.data.team.acf.team
    const slideCopy = [
      "",
      data.caption_1,
      data.caption_2,
      data.caption_3,
      data.caption_4,
      data.caption_5,
    ]
    return (
      <Layout>
        <SEO title="Dr. Shenilee Hazell - Dentist in Trinidad & Tobago" />
        <IntroHeader slides={data.slideshow} copies={slideCopy} />
        <ServiceContainer style={{ background: "white", paddingTop: 40 }}>
          <ServiceTabs />
        </ServiceContainer>
        <Invisalign />
        <FeaturedImage />
        <IntroSection
          intro={data.site_intro}
          testimonials={this.props.data.testimonials.edges}
        />
        <OurServices services={services} />
        <MeetTheTeam teamData={teamData} />
        <NewPatient />
      </Layout>
    )
  }
}

export default IndexPage

export const dentistryQuery = graphql`
  query(
    $homepage: String = "Home"
    $category: String = "Google Review"
    $type: String = "Service"
  ) {
    home: allWordpressPage(filter: { title: { eq: $homepage } }) {
      edges {
        node {
          title
          content
          acf {
            slideshow {
              source_url
              alt_text
              localFile {
                childImageSharp {
                  fluid(quality: 95, maxWidth: 2500) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            site_intro
            caption_1
            caption_2
            caption_3
            caption_4
            caption_5
          }
        }
      }
    }
    testimonials: allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: $category } } } }
    ) {
      edges {
        node {
          title
          content
        }
      }
    }
    services: allWordpressPage(
      filter: { acf: { type: { eq: $type } } }
      sort: { fields: menu_order, order: ASC }
    ) {
      edges {
        node {
          title
          content
          slug
        }
      }
    }
    team: wordpressPage(title: { eq: "Home" }) {
      acf {
        team {
          image {
            source_url
          }
          name
          role
        }
      }
    }
  }
`

import React from "react"
import styled, { keyframes } from "styled-components"
import { Colors } from "../colors/Colors"
import { fadeIn } from "../styles/animations"
import { navigate } from "gatsby"

const MainContainer = styled.div`
  position: relative;
  /* top: 55vh; */
  /* left: 0; */
  /* right: 0; */
  max-width: 1000px;
  margin: 0 auto;
  /* z-index: 0; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background: rgba(255, 255, 255, 1);

  @media (max-width: 850px) {
    display: none;
  }
`
const Tab = styled.div`
  background: rgba(255, 255, 255, 1);
  border: 1px solid #daccb0;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px;
  /* box-shadow: 0 0 10px rgba(155, 155, 155, 0.5); */
  height: 75px;
  padding: 0 8.5%;
  cursor: pointer;
  opacity: 0;
  animation: ${fadeIn} 1.4s ease-in-out 0.5s 1 forwards;
  transition: 0.4s ease-in-out;

  :hover {
    transform: translateY(-8px);
    background: rgba(255, 255, 255, 0.9);

    box-shadow: 0 8px 20px rgba(155, 155, 155, 0.55);
  }
`
const Title = styled.h4`
  text-align: center;
  margin: 0;
  font-size: 16px;
  font-family: "Articulat";
  font-weight: bold;
  color: #202020;
`

const ServiceTabs = props => (
  <>
    <p style={{ color: Colors.gold, textAlign: "center", fontWeight: 600 }}>
      Get Started Quickly
    </p>
    <MainContainer>
      {categories.map((category, index) => (
        <Tab onClick={() => navigate(`${category.link}`)} key={index}>
          <Title>{category.title}</Title>
        </Tab>
      ))}
    </MainContainer>
  </>
)

export default ServiceTabs

const categories = [
  {
    title: "Invisalign",
    link: "/dentistry/invisalign",
  },
  {
    title: "General Dentistry",
    link: "/dentistry/general-dentistry",
  },
  {
    title: "Cosmetic Dentistry",
    link: "/dentistry/cosmetic-dentistry",
  },
  {
    title: "Children's Dentistry",
    link: "/dentistry/childrens-dentistry",
  },
  {
    title: "Oral Surgery",
    link: "/dentistry/general-dentistry",
  },
]

import React, { Component } from "react"
import styled from "styled-components"
import MainButton from "./main-button"
import { Link } from "gatsby"
import Fade from "react-reveal/Fade"
import ReactAliceCarousel from "react-alice-carousel"
import TestimonialCard from "./testimonial-card"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Colors } from "../colors/Colors"
import { GoPrimitiveDot } from "react-icons/go"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import { openPopupWidget } from "react-calendly"

import {
  CALENDLY_PAGE_SETTINGS,
  CALENDLY_URL,
} from "../../shared/project-constants"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
}

const stagePadding = {
  paddingLeft: 0, // in pixels
  paddingRight: 0,
}

const responsive = {
  0: {
    items: 1,
  },
  1024: {
    items: 1,
  },
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 75px 50px 50px;

  .alice-carousel__wrapper {
    padding: 40px 0;
  }
`
const Logo = styled.img`
  max-width: 90px;
  object-fit: contain;
  margin: 0;
`
const Copy = styled.p`
  font-family: "Articulat";
  font-weight: 600;
  font-size: 1.4rem;
  color: #404040;
  line-height: 2.2rem;
  max-width: 25ch;
  text-align: center;
  margin: 30px 0;
`
const NavContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`
const Nav = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
`
const Item = styled.li`
  margin-right: 10px;
  transition: 0.3s ease;
  cursor: pointer;
  color: ${props => (props.active ? Colors.gold : "black")};
`
const ReviewsContainer = styled.div`
  position: relative;
  width: 90%;
  margin: 0 auto;
`
const ArrowLeft = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: 20px;
  cursor: pointer;
  z-index: 10;

  @media (max-width: 768px) {
    left: -20px;
  }
`
const ArrowRight = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 20px;
  cursor: pointer;
  z-index: 10;

  @media (max-width: 768px) {
    right: -20px;
  }
`
class IntroSection extends React.PureComponent {
  state = {
    currentIndex: 0,
  }

  openCalendlyWidget = () => {
    openPopupWidget({
      url: CALENDLY_URL,
      prefill: {},
      pageSettings: CALENDLY_PAGE_SETTINGS,
      utm: {},
    })
  }

  slideTo = num => {
    this.setState({ currentIndex: num })
  }

  handleOnSlideChange = event => {
    const { itemsInSlide, item } = event
    this.setState({ itemsInSlide, currentIndex: item })
  }

  createDots = () => {
    let Dots = []
    for (let i = 0; i < this.props.testimonials.length; i++) {
      Dots.push(
        <Item key={i} onClick={() => this.slideTo(i)}>
          <GoPrimitiveDot
            color={this.state.currentIndex === i ? Colors.gold : "#E9E9E9"}
            size={16}
          />
        </Item>
      )
    }
    return Dots
  }

  render() {
    const { currentIndex } = this.state
    return (
      <Container>
        <Logo src={require("../images/logo_alt.png")} />
        <Fade>
          <Copy>{this.props.intro}</Copy>
        </Fade>

        {/* <ReviewsContainer>
          <ArrowLeft onClick={() => this.slideTo(currentIndex === 0 ? 0 : currentIndex - 1)}>
            <FaChevronLeft />
          </ArrowLeft>
          <ArrowRight onClick={() => this.slideTo(currentIndex === this.props.testimonials.length - 1 ? this.props.testimonials.length - 1 : currentIndex + 1)}>
            <FaChevronRight />
          </ArrowRight>
          <ReactAliceCarousel
            stagePadding={stagePadding}
            buttonsDisabled
            dotsDisabled
            autoPlayInterval={8000}
            autoPlayDirection="ltr"
            stopAutoPlayOnHover
            autoPlay={true}
            onSlideChanged={this.handleOnSlideChange}
            slideToIndex={this.state.currentIndex}
            responsive={responsive}

          >
            {this.props.testimonials.map((testimonial, index) => (
              <TestimonialCard key={index} author={testimonial.node.title} review={testimonial.node.content} />
            ))}
          </ReactAliceCarousel>
        </ReviewsContainer>
        <NavContainer>
          <Nav>
            {this.createDots()}
          </Nav>
        </NavContainer> */}
        {/* <Link to="/appointment"> */}
        <MainButton onClick={this.openCalendlyWidget}>
          Book a smile today!
        </MainButton>
        {/* </Link> */}
      </Container>
    )
  }
}

export default IntroSection

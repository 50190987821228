import React from "react"
import styled from "styled-components"
import { SectionTitle } from "./Section-Title"
import OurServiceItem from "./our-service-item"
import MainButton from "./main-button"
import Fade from "react-reveal/Fade"

import tooth from "../images/icons/tooth.png"
import tooth_cracked from "../images/icons/tooth_cracked.png"
import tooth_prevent from "../images/icons/tooth_prevent.png"
import tooth_child from "../images/icons/tooth_child.png"
import { Link } from "gatsby"

const Container = styled.div`
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
`
const ServicesContainer = styled.div`
  margin: 0 auto;
  margin: 50px 0 0;
  max-width: 600px;
`

const OurServices = props => (
  <Container>
    <SectionTitle>Our Services</SectionTitle>
    <ServicesContainer>
      <Fade right distance="20px">
        {props.services.map((service, index) => (
          <OurServiceItem
            icon={tooth}
            key={index}
            title={service.node.title}
            copy={service.node.content}
            link={`dentistry/${service.node.slug}`}
          />
        ))}
      </Fade>
    </ServicesContainer>
    <MainButton>
      <Link style={{ color: "white", textDecoration: "none" }} to="/dentistry">
        See our full service list
      </Link>
    </MainButton>
  </Container>
)

export default OurServices

const services = [
  {
    title: "General Dentistry",
    copy:
      "General dentistry aims to ensure that your mouth is free from any oral disease or pathology and ensure a healthy mouth.",
    icon: tooth,
    link: "dentistry/general-dentistry",
  },
  {
    title: "Cosmetic Dentistry",
    copy:
      "Cosmetic dentistry aims to improve the aesthetic appearance of your smile and results in a more confident you!",
    icon: tooth_cracked,
    link: "dentistry/cosmetic-dentistry",
  },
  {
    title: "Preventitive Dentistry",
    copy:
      "Let’s get you the smile you deserve. We can help you improve your smile  whether it be with veneers or just a quick teeth whitening. Smile Inn has got you covered!",
    icon: tooth_prevent,
    link: "dentistry/preventitive",
  },
  {
    title: "Children's Dentistry",
    copy:
      "Trust us to ensure that your child gets the proper care and attention they deserve while on the chair! Lorem ipsum sd adsjalsadh jd ashjd sahd jdaskjdashdk saj d as kj ",
    icon: tooth_child,
    link: "dentistry/childrens-dentistry",
  },
]
